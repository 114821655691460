@font-face {
  font-family: "redianswericon";
  src: url('../fonts/redianswericon.eot');
  src: url('../fonts/redianswericon.eot?#iefix') format('eot'),
    url('../fonts/redianswericon.woff2') format('woff2'),
    url('../fonts/redianswericon.woff') format('woff'),
    url('../fonts/redianswericon.ttf') format('truetype'),
    url('../fonts/redianswericon.svg#redianswericon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.ra:before {
  display: inline-block;
  font-family: "redianswericon";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ra-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.ra-2x { font-size: 2em; }
.ra-3x { font-size: 3em; }
.ra-4x { font-size: 4em; }
.ra-5x { font-size: 5em; }
.ra-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.ra-account:before { content: "\EA01" }
.ra-add:before { content: "\EA02" }
.ra-addnote:before { content: "\EA03" }
.ra-archive:before { content: "\EA04" }
.ra-arrow-circle-left:before { content: "\EA05" }
.ra-arrow-circle-right:before { content: "\EA06" }
.ra-arrow-o:before { content: "\EA07" }
.ra-assign:before { content: "\EA08" }
.ra-attach:before { content: "\EA09" }
.ra-audio:before { content: "\EA0A" }
.ra-audio2:before { content: "\EA0B" }
.ra-available:before { content: "\EA0C" }
.ra-away:before { content: "\EA0D" }
.ra-back-thin:before { content: "\EA0E" }
.ra-back:before { content: "\EA0F" }
.ra-backup:before { content: "\EA10" }
.ra-backup2:before { content: "\EA11" }
.ra-billing:before { content: "\EA12" }
.ra-calendar:before { content: "\EA13" }
.ra-caret-down:before { content: "\EA14" }
.ra-caret-up:before { content: "\EA15" }
.ra-chat:before { content: "\EA16" }
.ra-check:before { content: "\EA17" }
.ra-click:before { content: "\EA18" }
.ra-copy:before { content: "\EA19" }
.ra-copy2:before { content: "\EA1A" }
.ra-delete-record:before { content: "\EA1B" }
.ra-delete:before { content: "\EA1C" }
.ra-doc:before { content: "\EA1D" }
.ra-drag:before { content: "\EA1E" }
.ra-edit:before { content: "\EA1F" }
.ra-facebook:before { content: "\EA20" }
.ra-facebook2:before { content: "\EA21" }
.ra-facility-profile:before { content: "\EA22" }
.ra-fax:before { content: "\EA23" }
.ra-fee:before { content: "\EA24" }
.ra-files:before { content: "\EA25" }
.ra-filter:before { content: "\EA26" }
.ra-finish:before { content: "\EA27" }
.ra-first-page:before { content: "\EA28" }
.ra-folder:before { content: "\EA29" }
.ra-gift:before { content: "\EA2A" }
.ra-home:before { content: "\EA2B" }
.ra-hp:before { content: "\EA2C" }
.ra-imessage:before { content: "\EA2D" }
.ra-info:before { content: "\EA2E" }
.ra-info2:before { content: "\EA2F" }
.ra-issues:before { content: "\EA30" }
.ra-list:before { content: "\EA31" }
.ra-mail-o:before { content: "\EA32" }
.ra-mail:before { content: "\EA33" }
.ra-member:before { content: "\EA34" }
.ra-messager:before { content: "\EA35" }
.ra-minus:before { content: "\EA36" }
.ra-mute:before { content: "\EA37" }
.ra-newnote:before { content: "\EA38" }
.ra-newpatient:before { content: "\EA39" }
.ra-note:before { content: "\EA3A" }
.ra-notice:before { content: "\EA3B" }
.ra-openshift:before { content: "\EA3C" }
.ra-payment:before { content: "\EA3D" }
.ra-pen:before { content: "\EA3E" }
.ra-pharmacy:before { content: "\EA3F" }
.ra-photo:before { content: "\EA40" }
.ra-play-o:before { content: "\EA41" }
.ra-play:before { content: "\EA42" }
.ra-plus:before { content: "\EA43" }
.ra-policy:before { content: "\EA44" }
.ra-post:before { content: "\EA45" }
.ra-print:before { content: "\EA46" }
.ra-profile:before { content: "\EA47" }
.ra-provider:before { content: "\EA48" }
.ra-question:before { content: "\EA49" }
.ra-records:before { content: "\EA4A" }
.ra-recycle:before { content: "\EA4B" }
.ra-remove:before { content: "\EA4C" }
.ra-report:before { content: "\EA4D" }
.ra-return:before { content: "\EA4E" }
.ra-save:before { content: "\EA4F" }
.ra-scheduling:before { content: "\EA50" }
.ra-search:before { content: "\EA51" }
.ra-setting:before { content: "\EA52" }
.ra-settings2:before { content: "\EA53" }
.ra-share:before { content: "\EA54" }
.ra-signout:before { content: "\EA55" }
.ra-skype:before { content: "\EA56" }
.ra-sort-asc:before { content: "\EA57" }
.ra-sort-dec:before { content: "\EA58" }
.ra-sound:before { content: "\EA59" }
.ra-spinner:before { content: "\EA5A" }
.ra-star-o:before { content: "\EA5B" }
.ra-star:before { content: "\EA5C" }
.ra-support:before { content: "\EA5D" }
.ra-switch:before { content: "\EA5E" }
.ra-theme:before { content: "\EA5F" }
.ra-trash:before { content: "\EA60" }
.ra-twiteer:before { content: "\EA61" }
.ra-unassign:before { content: "\EA62" }
.ra-uninvite:before { content: "\EA63" }
.ra-unmerge:before { content: "\EA64" }
.ra-upload:before { content: "\EA65" }
.ra-user:before { content: "\EA66" }
.ra-verification:before { content: "\EA67" }
.ra-video:before { content: "\EA68" }
.ra-view:before { content: "\EA69" }
.ra-waitingroom:before { content: "\EA6A" }
.ra-zoomus:before { content: "\EA6B" }
